<template>
  <div>
    <VueHtml2pdf
       :show-layout="false"
       :float-layout="true"
       :enable-download="false"
       :preview-modal="true"
       :paginate-elements-by-height="1400"
       filename="myPDF"
       :pdf-quality="2"
       :manual-pagination="false"
       pdf-format="a10"
       pdf-orientation="landscape"
       pdf-content-width="800px"
       ref="html2Pdf"
   >
       <section slot="pdf-content">
        <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
          <h5 class="mb-0">
            Buscar Paciente
          </h5>
          <b-badge variant="light-primary">
            Myt
          </b-badge>
          <small class="text-muted w-100" />
        </b-card-header>
       </section>
   </VueHtml2pdf>
   <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      @click="generateReport()"
    >
      Buscar
    </b-button>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import {
  BButton, BCardHeader, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'


export default { 
  directives: {
    Ripple,
  },
   components: {
       VueHtml2pdf,
       BButton,
       BCardHeader,
       BBadge,
   },
   methods: { 
       generateReport () {
           this.$refs.html2Pdf.generatePdf()
       }
   },
}
</script>